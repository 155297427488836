import _ from "lodash"
import React from "react"
import { useState } from "react"
import { NavigateFunction, useNavigate, useParams } from "react-router-dom"
import { PROGRAMMED_MODULE, SESSION } from "../../../consts/methods.consts"
import { PROGRAMMED_MODULE_TAGS, SESSION_TAGS } from "../../../consts/tags.consts"
import { useGetQuery } from "../../../services/api.service"
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable"
import PageHeader from "../../common/page-header/PageHeader"
import { SectionCard } from "../../common/section-card/SectionCard"
import { useTableDataProvider } from "../../hooks"
import { DefaultCell } from "../report/components/cells"

const ProgramModuleApprovalListPage = () => {
    const navigate: NavigateFunction = useNavigate()
    const { id } = useParams()
    const [page, setPage] = useState(1)
    const [length, setLength] = useState(15)

    const { data, isLoading, isFetching } = useGetQuery({
        method: PROGRAMMED_MODULE.LIST_APPROVAL,
        body: {
            page: page,
            length: Number(length),
        },
        providesTags: [PROGRAMMED_MODULE_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const tableLineClickHandler = (line: any) => {
        navigate(`/app/program-module/${_.get(line, 'program_module')}`)
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    const PROGRAM_MODULE_APPROVAL_LIST_COLUMNS: ChakraTableColumns = [
        {
            header: "Code",
            width: 200,
            accessor:
                "module_code",
            customRenderer: (value: any, item: any) => {
                const delayed = _.get(item, 'delayed', false)
                return (
                    <div>
                        <p onClick={() => tableLineClickHandler(item)} className={delayed ? "text-red-500" : "text-black"}> {value}</p>
                    </div>
                )
            }
        },
        {
            header: "Name",
            width: 500,
            accessor:
                "title",
            customRenderer: (value: any, item: any) => {
                const delayed = _.get(item, 'delayed', false)

                return (
                    <div>
                        <p onClick={() => tableLineClickHandler(item)} className={delayed ? "text-red-500" : "text-black"}> {value}</p>
                    </div>
                )
            }
        },
        {
            header: "Program",
            width: 700,
            accessor:
                "program_title",
            // customRenderer: (value: any, item: any) => (
            //     React.createElement(DefaultCell, { navigateTo: `/app/program/${_.get(item, 'program')}`, value: value })
            // ),
        },
        {
            header: "Start Date",
            accessor:
                "formatted_start_date",
            width:200
        },
    ]


    return (

        <div>
            <PageHeader subtitle="View and Manage Program Module Approval" title={`Program Module Approval`} />
            <SectionCard backgroundColor="bg-white" title="Program Module" p={3}>
                <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"}
                    onSelected={tableLineClickHandler} columns={PROGRAM_MODULE_APPROVAL_LIST_COLUMNS} data={dataList} />

            </SectionCard>
        </div>

    )
}

export default ProgramModuleApprovalListPage