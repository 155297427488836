import { useColorModeValue, Stack, HStack, Text, Box, Icon, Heading, useBreakpointValue, Badge } from "@chakra-ui/react"
import _ from "lodash";
import { FiMoreVertical, FiArrowUpRight, FiArrowDownRight } from "react-icons/fi"
import { PiGraduationCapThin } from "react-icons/pi";
import { Navigate, useNavigate } from "react-router-dom";
import { MdOutlinePendingActions } from "react-icons/md";

interface Props {
    label: string
    value: string
    link?: string,
    icon?: any,
    delta?: {
        value: string
        isUpwardsTrend: boolean
    },
    className?: string
}

export const StatCard = (props: Props) => {
    const { label, value, delta, link, icon, ...boxProps } = props
    const navigate = useNavigate()

    const onclick = () => {
        if (link) navigate(link)
    }

    return (
        <Box
            onClick={onclick}
            className="border border-x-2 border-b-2 !shadow-[0_4px_4px_-4px_rgba(184,177,184,0.43)] bg-white border-gray-200 rounded-xl"
            px={{ base: '4', md: '5' }}
            py={{ base: '4', md: '5' }}
            cursor={link ? 'pointer' : ''}
            bg="bg-surface"
            borderRadius="xl"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            {...boxProps}>
            <Stack>
                <HStack justify="space-between">
                    <Text className="line-clamp-2" fontSize="sm" fontWeight={"md"} color="#09090B">
                        {label}
                    </Text>
                    <Icon as={icon ? _.get(iconConfig, icon, 'PiGraduationCapThin') : PiGraduationCapThin} boxSize="5" color="muted" />
                </HStack>
                <HStack justify="space-between">
                    <Heading color="#09090B" size={useBreakpointValue({ base: 'sm', md: 'lg' })}>{value ? value : 0}</Heading>
                    {delta && <Badge variant="subtle" colorScheme={delta.isUpwardsTrend ? 'green' : 'red'}>
                        <HStack spacing="1">
                            <Icon as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight} />
                            <Text>{delta.value ? delta.value : 0}</Text>
                        </HStack>
                    </Badge>}
                </HStack>
            </Stack>
        </Box>
    )
}

const iconConfig = {
    "PiGraduationCapThin": PiGraduationCapThin,
    "MdOutlinePendingActions": MdOutlinePendingActions
}