import _, { get } from "lodash";
import React from "react";
import { BadgeCell, DefaultCell, ResourcePersonCell } from "./components/cells";
import * as Yup from 'yup';
import { printAction } from "../../../actions";
import { PRINT } from "../../../consts/methods.consts";

const ModulePlanConfig = {
  searchApi: "xceed.api.report.programmed_module.generate_pm_workflow_report",
  alert:
    "Reminder: Specify a date range to receive reports. Use other fields to further narrow down data within the required date range.",
  excelFileName: "resource-person-report",
  title: "Module Plan Approval/Recommendation Pending Report",
  extraActionsAsMenu: true,
  sections: [
    {
      selectionTitle: "Date Range",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          is_required: true,
          label: "From",
          type: "date",
          key: "from",
          validationSchema: Yup.string().required('From date is required')
        },
        {
          label: "To",
          type: "date",
          key: "to",
        },
      ],
    },
    {
      selectionTitle: "Filters",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          label: "Module Coordinator",
          key: "mc",
          type: "form-select",
          optionMethod: "xceed.api.report.programmed_module.get_mc_list",
        },
        {
          label: "Program Assistant",
          key: "pa",
          type: "form-select",
          optionMethod: "xceed.api.report.programmed_module.get_pa_list"
        },
      ],
    },
  ],

  tableConfig: {
    column: [
      {
        header: "Module plan Name",
        accessor: "module",
        width: 300,
      },
      {
        header: "MC Name",
        accessor: "mc",
        width: 200,
      },
      {
        header: "PA Name",
        accessor: "pa",
        width: 200,
      },
      {
        header: "Has Already Module Started",
        accessor: "started",
        width: 200,
        customRenderer: (value: any, item: any) =>
          React.createElement(BadgeCell, {
            config: statusBadgeConfig,
            value: value,
          }),
      },
      {
        header: "Module Start Date",
        accessor: "start_date",
        width: 200,
      },
      {
        header: "Assigned to Registrar on",
        accessor: "assigned_date_to_registrar",
        width: 200,
      },
      {
        header: "Assigned to MC on",
        accessor: "assigned_date_to_mc",
        width: 200,
      },
      {
        header: "Assigned to ADG on",
        accessor: "assigned_date_to_adg",
        width: 200,
      },
      {
        header: "Assigned to DG on",
        accessor: "assigned_date_to_dg",
        width: 200,
      },
      // {
      //   header: "No of days pending with officer",
      //   accessor: "no_of_days_pending_with_officer",
      //   width: 200,
      // },
    ],
  },
  actions: [
    {
      text: "Print Report",
      onClick: async (filter: any, data: any, setLoading: any) => {
        if (filter.from) {
          setLoading(true);
          try {
            await printAction(PRINT.PRINT_PROGRAMMED_MODULE_WORKFLOW_BULK_REPORT, { filter }, setLoading);
          } catch (error: any) {
            setLoading(false);
            const newError: any = new Error("Unable to print report");
            newError.title = "Error";
            newError.status = "error";
            throw newError;
          }
        } else {
          const error: any = new Error(
            "Please select from date "
          );
          error.title = "Warning";
          error.status = "warning";
          throw error;
        }
      },
      buttonVariant: "solid",
      buttonColorScheme: "teal",
    }
  ],
};

export default ModulePlanConfig;

const statusBadgeConfig = {
  No: "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded",
  Yes: "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded",
};
