import { Badge, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ATTENDANCE, MODULE_ASSESMENT_RESULTS, MODULE_ENROLLMENT, PROGRAMMED_MODULE } from "../../../consts/methods.consts";
import { AMENDMENT_TAG, ATTENDANCE_TAGS, DROPDOWN_TAGS, MODULE_ASSESMENT_RESULT_TAGS, MODULE_ENROLLMENT_TAGS, PROGRAMMED_MODULE_TAGS, PROGRAM_TAGS, SESSION_TAGS, TRNSAC_TAG } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { customeJsonParser } from "../../../utils/utils";
import CommentSelection from "../../common/comment-section/CommentSection";
import DocumentHeaderTitle from "../../common/document-header-title/DocumentHeaderTitle";
import { FormInput } from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import { FormSeletectNavigationController } from "../../common/form-seletect-navigation-controller/FormSeletectNavigationController";
import InnerLoading from "../../common/inner-loading/InnterLoading";
import NewComment from "../../common/new-comment/NewComment";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard } from "../../common/section-card/SectionCard";
import { useObjChange } from "../../hooks";
import ModuleEnrollmentListPage from "../module-enrollment/ModuleEnrollmentListPage";
import SessionListPage from "../session/SessionListPage";
import AssessmentListTable from "./components/assessment/AssessmentListTable";
import PrintTemplateSelector from "./components/print-template-selector/PrintTemplateSelector";
import UploadExcelModal from "./components/UploadExcelModal";
import AmendmentRequestModal from "./components/amendment-request-modal/AmendmentRequestModal";
import InstructionBox from "../../common/instruction-box/InstructionBox";
import axios from "axios";
import { BASE_URL } from "../../../consts/app.consts";
import UploadAttendanceModal from "../session/components/UploadAttendanceModal";

const ViewProgrammedModule = () => {
    const { id } = useParams()
    const navigate: NavigateFunction = useNavigate();
    const disclosure = useDisclosure()
    const assesmentModalDisclosure = useDisclosure()
    const createAmendmentModalDisclosure = useDisclosure()
    const printTemplateSelectorDisclosure = useDisclosure()
    const attendanceUploadDisclosure = useDisclosure()

    const toast = new ToastService(useToast());
    const [create, { isLoading: creating }] = usePostMutation();
    const [loading, setLoading] = useState(false)

    const { data: _data, isLoading } = useGetQuery({
        method: PROGRAMMED_MODULE.GET,
        body: { id },
        providesTags: [PROGRAMMED_MODULE_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})
    // const isEditable = _.get(data, 'is_editable')
    const allowedToSave = _.get(data, 'allowed_to_save_pm')
    const allowedToAmend = _.get(data, 'allowed_to_amend_pm')
    const formDisable = !allowedToAmend && !allowedToSave
    const requestedBy = _.get(data, 'amendment_owner_to_display', '')

    const is_allowed_to_create_amendment = _.get(data, 'allowed_to_create_amendment_request')
    const allowed_to_close_amendment = _.get(data, 'allow_close_amendment')
    const amendment_status = _.get(data, 'amendment_status')
    const AllowedEditableField = _.get(data, 'allowed_field')
    const workflowActions = _.get(data, 'workflow_actions', []).map((item: any, index: any) => (
        {
            text: item?.action,
            onClick: () => { onClickAction(item?.action) },
            buttonVariant: "solid",
            buttonColorScheme: item?.action?.toLowerCase().includes("rej") ? "red" : "teal"
        }
    ))

    const stringifyData = JSON.stringify(data)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            code: Yup.string().required("Code is required"),
            program: Yup.string().required("Program is required"),
            lecturer: Yup.string().required("Lecturer Code is required"),
            end_date: Yup.date()
                .required("End Date is required")
                .test(
                    "to-date-validation",
                    "End date must be after start date",
                    (value, context) => {
                        const { start_date } = context.parent;
                        return start_date ? value >= start_date : true;
                    }
                ),
            start_date: Yup.date()
                .required("Start Date is required")
                .test(
                    "from-date-validation",
                    "Start date must be before end date",
                    (value, context) => {
                        const { end_date } = context.parent;
                        return end_date ? value <= end_date : true;
                    }
                ),
            enrollment_closing_date: Yup.date()
                .required("Registration closing Date is required")
                .test(
                    "enrollment-closing-date-validation",
                    "Registration closing date must be on or before the start date",
                    (value, context) => {
                        const { start_date } = context.parent;
                        return start_date ? value <= start_date : true;
                    }
                ),
            assessment_criteria: Yup.string().required("Assessment Criteria is required")
        }),
        onSubmit: async (values: any) => {
            try {
                await create({
                    method: PROGRAMMED_MODULE.UPDATE,
                    body: values,
                    invalidatesTags: [PROGRAMMED_MODULE_TAGS.VIEW, PROGRAMMED_MODULE_TAGS.LIST, DROPDOWN_TAGS.LIST, AMENDMENT_TAG.VIEW, AMENDMENT_TAG.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("We've updated the program module").showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const { data: initialData } = useGetQuery({
        method: PROGRAMMED_MODULE.INIT,
        providesTags: [DROPDOWN_TAGS.LIST]
    })

    const dropDownData = _.get(initialData, 'message')

    const { isChanged, setInitialObj } = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [stringifyData])

    const onselectTab = () => {
        // setSearchParams('')
    }

    const onClickAction = async (action: any) => {
        if (_.get(data, 'approved_status') == 0 || _.get(data, 'approved_status') == 1) {
            if (isChanged) {
                toast.setTitle("warning").setDescription("Please save the changes before execute action").setStatus("warning").show();
                return
            }
        }
        try {
            await create({
                method: PROGRAMMED_MODULE.EXECUTE_WORKFLOW_ACTION,
                body: { id, action },
                invalidatesTags: [SESSION_TAGS.VIEW, TRNSAC_TAG.LIST, SESSION_TAGS.LIST, PROGRAMMED_MODULE_TAGS.LIST, PROGRAMMED_MODULE_TAGS.VIEW, PROGRAM_TAGS.VIEW]
            }).unwrap();
            toast.setTitle("Success").setDescription("Your action has been completed.").showSuccessToast();

            const res = await create({
                method: PROGRAMMED_MODULE.GET_TRANSACTION,
                body: { _id: id },
                invalidatesTags: [TRNSAC_TAG.LIST]
            }).unwrap();

            const remainingActions = _.get(res, 'message')

            if (_.isArray(remainingActions) && _.size(remainingActions) > 0) {
                return
            }

            setTimeout(() => {
                navigate(-1);
            }, 1500)

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }
    }

    const markAsCloseHadler = () => create({
        method: PROGRAMMED_MODULE.MARK_AS_CLOSE,
        body: { id },
        invalidatesTags: [PROGRAMMED_MODULE_TAGS.LIST, PROGRAMMED_MODULE_TAGS.VIEW, DROPDOWN_TAGS.LIST]
    }).unwrap().then(() => {
        toast.setTitle("Success").setDescription("We've closed the program module").showSuccessToast();
    }).catch((error: any) => {
        let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
        if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
    })

    const closeAmendmentRequest = () => {
        create({
            method: PROGRAMMED_MODULE.CLOSE_AMENDMENTREQUEST,
            body: { id },
            invalidatesTags: [PROGRAMMED_MODULE_TAGS.LIST, PROGRAMMED_MODULE_TAGS.VIEW, DROPDOWN_TAGS.LIST]
        }).unwrap().then(() => {
            toast.setTitle("Success").setDescription("We've closed the amendment request").showSuccessToast();
        }).catch((error: any) => {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        })
    }


    const onDownloadAttendanceTemplateHandler = async () => {
        try {
            let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.attendance.download_module_base_attendance_sheet`, { "pm": id }, {
                withCredentials: true,
                responseType: "blob"
            })
            if (response) {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `attendance-sheet.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }

        } catch (error: any) {
            toast.setDescription('Unable to Download attendance sheet').setTitle('Error').showErrorToast()
        }
    }

    const actions: PageHeaderActions =
        allowedToAmend ? [{
            text: "Save Amendments",
            isDisabled: _.get(data, 'is_closed') || !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        ] : [
            {
                text: "Save",
                isDisabled: _.get(data, 'is_closed') || !isChanged || !allowedToSave,
                onClick: formik.submitForm,
                buttonVariant: "solid",
                buttonColorScheme: "teal"
            }
        ]

    const moreActions: PageHeaderActions = [
        ...allowed_to_close_amendment ? [{
            text: "Close Amendment Request",
            onClick: closeAmendmentRequest,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }] : [],
        ...is_allowed_to_create_amendment ? [{
            text: "Create Amendment Request",
            onClick: createAmendmentModalDisclosure.onOpen,
            confirmation: false,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }] : [],
        {
            text: "Mark as Completed",
            isDisabled: _.get(data, 'is_closed'),
            onClick: markAsCloseHadler,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Enroll Students from Excel",
            onClick: () => disclosure.onOpen(),
            buttonVariant: "solid",
            confirmation: false,
            buttonColorScheme: "teal"
        },
        {
            text: "Import Assesment Results",
            onClick: () => assesmentModalDisclosure.onOpen(),
            buttonVariant: "solid",
            confirmation: false,
            buttonColorScheme: "teal"
        },
        {
            text: "Download Attendance Template",
            onClick: onDownloadAttendanceTemplateHandler,
            buttonVariant: "solid",
            confirmation: false,
            buttonColorScheme: "teal"
        },
        {
            text: "Upload Attendance from Excel",
            onClick: () => attendanceUploadDisclosure.onOpen(),
            buttonVariant: "solid",
            confirmation: false,
            buttonColorScheme: "teal"
        },
        {
            text: "Print Session Plan",
            confirmation: false,
            onClick: () => printTemplateSelectorDisclosure.onOpen(),
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        ...workflowActions
    ]

    const statusRenderer = (
        <>
            <Badge className="mt-[6px]" variant='solid' colorScheme={_.get(moduleStatusColour, _.get(data, 'is_closed'))}>
                {_.get(moduleStatus, _.get(data, 'is_closed'))}
            </Badge>
            <Badge className="mt-[6px]" variant='solid' colorScheme={_.get(colourByStatus, _.get(data, 'approved_status'))}>
                {_.get(data, 'workflow_state')}
            </Badge>
            <Badge className="mt-[6px]" variant='solid' colorScheme={_.get(colorByAmendmentStatus, amendment_status)}>
                {_.get(statusByAmendmentStatus, amendment_status)}
            </Badge>
        </>
    )

    if (isLoading || loading) {
        return (
            <>
                <PageHeader isLoading={isLoading} enableBackButton actions={actions} subtitle={'Program Module'} title={_.get(data, 'title', '')} />
                <InnerLoading />
            </>
        )
    }

    const enrollmentModalconfig = {
        program: _.get(data, 'program'),
        programModule: _.get(data, 'name'),
        uploadMethod: MODULE_ENROLLMENT.UPLOAD_EXCEL,
        invalidateTags: [MODULE_ENROLLMENT_TAGS.LIST]
    }

    const asssesmentModalconfig = {
        program: _.get(data, 'program'),
        programModule: _.get(data, 'name'),
        uploadMethod: MODULE_ASSESMENT_RESULTS.UPLOAD_EXCEL,
        invalidateTags: [MODULE_ASSESMENT_RESULT_TAGS.LIST]
    }

    const attendanceModalconfig: any = {
        programModule: _.get(data, 'name'),
        uploadMethod: ATTENDANCE.UPLOAD_EXCEL_FROM_PM,
        invalidateTags: [ATTENDANCE_TAGS.LIST]
    }


    return (
        <div>
            <PageHeader statusRenderer={statusRenderer} moreActions={moreActions} backButtonCofirmation={isChanged} isLoading={creating} enableBackButton actions={actions} title={_.get(data, 'title')}
                subtitle={'Program Module'} />

            <Tabs onChange={(index) => onselectTab()} className="mt-3">
                <TabList>
                    <Tab>Program Module</Tab>
                    <Tab>Sessions</Tab>
                    <Tab>Enrollments</Tab>
                    <Tab>Assessment Results</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <DocumentHeaderTitle>
                            {_.get(data, 'title', '')}
                        </DocumentHeaderTitle>
                        {(amendment_status == 3) && <div className="!p-0 !pt-3">
                            <InstructionBox title={'Amendment requested fields'} bottomText={requestedBy ? `Requested by ${requestedBy}` : ''}>
                                {/* <div className="text-md mb-3">Approved amendments</div> */}
                                {
                                    AllowedEditableField?.map((item: any, index: number) => (<li key={index}>{item}</li>))
                                }
                            </InstructionBox>
                        </div>}

                        <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                <FormSelect isDisabled={formDisable} options={_.get(dropDownData, 'resource_persons')} isRequired
                                    extraProps={{ linkTo: `/app/employee/${_.get(formik.values, 'lecturer')}` }}
                                    components={{ Control: FormSeletectNavigationController }}
                                    label="Module Coordinator" formik={formik}
                                    name="lecturer" />

                                <FormInput isDisabled={formDisable} isRequired type={'date'} label="Start Date" formik={formik}
                                    name="start_date" />

                                <FormInput isDisabled={formDisable} isRequired type={'date'} label="End Date" formik={formik}
                                    name="end_date" />
                                <FormInput isDisabled={formDisable} isRequired type={'date'} label="Registration Closing Date" formik={formik}
                                    name="enrollment_closing_date" />
                                <FormSelect isDisabled={formDisable} isRequired options={[{ value: 'Attendance', label: 'Attendance' },
                                { value: 'Examination/Presentation/Workshop', label: 'Examination/Presentation/Workshop' }
                                ]}
                                    label="Assessment Criteria" formik={formik}
                                    name="assessment_criteria" />
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard backgroundColor=" bg-white" title="Module & Program Inforamtion" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                <FormSelect isDisabled options={_.get(dropDownData, 'programes')} isRequired formik={formik}
                                    extraProps={{ linkTo: `/app/program/${_.get(formik.values, 'program')}` }}
                                    components={{ Control: FormSeletectNavigationController }}
                                    name={'program'}
                                    label={'Program'} />
                                <FormInput linkTo={`/app/modules/${_.get(formik.values, 'module')}`} isDisabled isRequired label="Module Code" formik={formik} name="code" />
                                <FormInput isDisabled isRequired label="Module Name" formik={formik} name="title" />

                                <FormInput isDisabled isRequired label="Type" formik={formik} name="type" />
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard backgroundColor=" bg-white" title="Add a comment" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                                <NewComment doctype={'Programmed Module'} id={id} tag={PROGRAMMED_MODULE_TAGS.COMMENTS} />
                            </SimpleGrid>
                        </SectionCard>
                        <CommentSelection doctype={'Programmed Module'} doc={id} tag={PROGRAMMED_MODULE_TAGS.COMMENTS} />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <SessionListPage parentData={{ program: _.get(data, 'program'), programModule: _.get(data, 'name'), allowedToAmend, allowedToSave }} />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ModuleEnrollmentListPage parentData={{ program: _.get(data, 'program'), programModule: _.get(data, 'name') }} />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <AssessmentListTable parentData={{ program: _.get(data, 'program'), programModule: _.get(data, 'name') }} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <UploadExcelModal config={enrollmentModalconfig} disclosure={disclosure} />
            <UploadExcelModal config={asssesmentModalconfig} disclosure={assesmentModalDisclosure} />
            <PrintTemplateSelector disclosure={printTemplateSelectorDisclosure} programed_module={id} setLoading={setLoading} />
            <AmendmentRequestModal disclosure={createAmendmentModalDisclosure} programed_module={id} setLoading={setLoading} />
            <UploadAttendanceModal config={attendanceModalconfig} disclosure={attendanceUploadDisclosure} />

        </div >
    )
}

export default ViewProgrammedModule


const moduleStatus = {
    1: 'Closed',
    0: 'Open'
}

const moduleStatusColour = {
    1: 'red',
    0: 'green'
}

const colourByStatus = {
    0: "red",
    1: "green",
    "-1": "red",
    2: "green",
    "-2": "red",
    3: "green",
    "-3": "red",
    4: "green",
    "-4": "red",
    5: "green",
    "-5": "red",

}

const colorByAmendmentStatus = {
    0: "green",
    1: "green",
    "-1": "red",
    2: "green",
    "-2": "red",
    3: "green",
    "-3": "red",
    "-4": "green",
    5: "gray"

}

const statusByAmendmentStatus = {
    0: "Revision Request Pending – Registrar",
    1: "Revision Request Pending – MC",
    "-1": "Revision Request Not Recommended – Registrar",
    2: "Revision Request Pending – ADG",
    "-2": "Revision Request Not Recommended – MC",
    3: "Revision Request Approved– ADG",
    "-3": "Revision Request Rejected– ADG",
    "-4": "Revised",
    "5": "No Revision"
}