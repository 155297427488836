import _ from "lodash";
import * as Yup from "yup";
import { PROGRAM } from "../../../consts/methods.consts";
import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useFormik } from "formik";
import { FormInput } from "../../common/form-controllers/FormInput";
import { SimpleGrid } from "@chakra-ui/layout";
import { useDispatch } from "react-redux";
import { SectionCard } from "../../common/section-card/SectionCard";
import { ToastService } from "../../../services/toast.service";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { getContactNumber } from "./actions";
import { BUDGET_TAGS, CALENDAR_TAGS, DROPDOWN_TAGS, PROGRAM_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { NavigateFunction, useNavigate } from "react-router-dom";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import FormSelect from "../../common/form-controllers/FormSelect";
import MaskFormInput from "../../common/form-controllers/MaskFormInput";
import { customeJsonParser } from "../../../utils/utils";
import { chakraComponents } from "chakra-react-select";
import { FormSelectColourController } from "../../common/form-select-colour-controller/form-select-colour-controller";
import FormOnlineSearch from "../../common/form-controllers/FormOnlineSearch";

//this page has rendering issue need to refactor 
const NewProgramPage = () => {
    const dispatch: any = useDispatch()
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const [create, { isLoading }] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            code: Yup.string().required("Code is required"),
            from: Yup.date()
                .required("From Date is required")
                .test(
                    "from-date-validation",
                    "Start date must be before end date",
                    (value, context) => {
                        const { to } = context.parent;
                        return to ? value <= to : true;
                    }
                ),
            to: Yup.date()
                .required("To Date is required")
                .test(
                    "to-date-validation",
                    "End date must be after start date",
                    (value, context) => {
                        const { from } = context.parent;
                        return from ? value >= from : true;
                    }
                ),
            module_bucket: Yup.string().required("Module Bucket is required"),
            mode: Yup.string().required("Mode is required"),
            credit: Yup.number().required("Credit is required"),
            coordinator: Yup.string().required("Coordinator is required"),
            assistance: Yup.string().required("Assistant is required"),
            organization: Yup.string().required("Organization is required"),
            // registration_closing_date: Yup.date().required("Registration Closing Date is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: PROGRAM.CREATE,
                    body: values,
                    invalidatesTags: [PROGRAM_TAGS.VIEW, PROGRAM_TAGS.LIST, DROPDOWN_TAGS.LIST, CALENDAR_TAGS.EVENTS]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Program creation failed")
                }

                toast.setTitle("Success").setDescription("Program has been created successfully.").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/program/${newId}`);
                }, 1500)

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const contactChange = _.get(formik.values, 'organization_contact')
    const organizationChange = _.get(formik.values, 'organization')

    useEffect(() => {

        dispatch(getContactNumber(_.get(formik.values, 'organization'), _.get(formik.values, 'organization_contact'))).then((data: any) => {

            if (data) {
                console.log('abcd', data)
                formik.setFieldValue('personal_mobile_number', _.get(data, 'personal_mobile_number') || '')
                formik.setFieldValue('official_mobile_number', _.get(data, 'official_mobile_number') || '')
                formik.setFieldValue('designation', _.get(data, 'designation') || '')
                formik.setFieldValue('email', _.get(data, 'email') || '')
            }
            else {
                formik.setFieldValue('organization_contact_number', '')
                formik.setFieldValue('personal_mobile_number', '')
                formik.setFieldValue('official_mobile_number', '')
                formik.setFieldValue('designation', '')
                formik.setFieldValue('email', '')

            }
        })

    }, [contactChange])


    const { data: initialData } = useGetQuery({
        method: PROGRAM.INIT,
        body: {
            organization: _.get(formik.values, 'organization', ''),
            category: _.get(formik.values, 'category', ''),
            program_type: _.get(formik.values, 'type', ''),
        },
        providesTags: [DROPDOWN_TAGS.LIST]
    })

    const { data: budgetData } = useGetQuery({
        method: PROGRAM.GET_BUDGET_DETAILS,
        body: {},
        providesTags: [BUDGET_TAGS.LIST]
    })

    useEffect(() => {
        formik.setFieldValue('organization_contact', '')
    }, [organizationChange])

    const dropDownData = _.get(initialData, 'message')
    const budgetDropDownData = _.get(budgetData, 'message.data')

    const onBudgetDocChange = (e: any) => {
        formik.setFieldValue('budgeted_amount', String(e.budget))
    }

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <div>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={'Create New Program'} subtitle={'Program'} />

            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired label="Code" formik={formik} name="code" />
                    <FormInput isRequired label="Program Name" formik={formik} name="title" />
                    <FormInput isRequired type="number" label="Credit" formik={formik}
                        name="credit" />

                    <FormDropdown options={_.get(dropDownData, 'module_buckets')} isRequired formik={formik}
                        name={'module_bucket'}
                        autoFocus
                        label={'Module Basket'} />
                    <FormDropdown options={programModes} isRequired formik={formik} name={'mode'}
                        autoFocus
                        label={'Mode'} />

                    <FormDropdown options={_.get(dropDownData, 'categories')} formik={formik}
                        name={'category'}
                        autoFocus
                        label={'Category'} />

                    <FormDropdown options={_.get(dropDownData, 'program_types')} formik={formik}
                        name={'type'}
                        autoFocus
                        label={'Type'} />

                    <FormDropdown options={_.get(dropDownData, 'program_subtypes')} formik={formik}
                        name={'sub_type'}
                        autoFocus
                        label={'Sub Type'} />

                    {/* <FormOnlineSearch method={PROGRAM.GET_BUDGET_DETAILS} formik={formik}
                        name={'budget_document'}
                        body={{ "id": "" }}
                        label={'Budget Document'}
                        metaDispatcher={onBudgetDocChange}
                    /> */}

                    <MaskFormInput maskOption={{
                        mask: Number,
                        thousandsSeparator: ',',
                        mapToRadix: ['.'],
                        radix: '.',
                        padFractionalZeros: true,
                        scale: 2,
                    }} label="Budgeted Amount (RS.)" formik={formik} name="budgeted_amount" />

                    <FormInput label="File Number" formik={formik}
                        name="file_number" />


                    {/* <FormTextArea label="Description" formik={formik} name="description" /> */}

                </SimpleGrid>
            </SectionCard>

            <SectionCard backgroundColor=" bg-white" title="Organization Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormSelect options={_.get(dropDownData, 'organizations')} isRequired formik={formik} name={'organization'}
                        label={'Organization'} />
                    <FormSelect options={_.get(dropDownData, 'organization_contacts')}
                        label="Organization Contact" formik={formik}
                        name="organization_contact" />

                    <MaskFormInput maskOption={{
                        mask: '000 0000000',
                    }} isDisabled label="Personal Contact Number" formik={formik}
                        name="personal_mobile_number" />

                    <MaskFormInput maskOption={{
                        mask: '000 0000000',
                    }} isDisabled label="Official Contact Number" formik={formik}
                        name="official_mobile_number" />

                    <FormInput isDisabled label="Designation" formik={formik}
                        name="designation" />
                    <FormInput isDisabled label="Email" formik={formik}
                        name="email" />
                </SimpleGrid>
            </SectionCard>

            <SectionCard backgroundColor=" bg-white" title="Employee Allocation" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormSelect isRequired options={_.get(dropDownData, 'program_coodinators')}
                        label="Program Coordinator" formik={formik}
                        name="coordinator" />

                    <FormSelect options={_.get(dropDownData, 'program_coodinators')}
                        label="Co-Coordinator" formik={formik}
                        name="co_coordinator" />

                    <FormSelect options={_.get(dropDownData, 'program_offices_and_assistance')}
                        label="Program Officer" formik={formik}
                        name="officer" />

                    <FormSelect isRequired options={_.get(dropDownData, 'program_offices_and_assistance')}
                        label="Program Assistant" formik={formik}
                        name="assistance" />
                </SimpleGrid>
            </SectionCard>

            <SectionCard backgroundColor=" bg-white" title="Duration Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired type={'date'} label="From" formik={formik} name="from" />
                    <FormInput isRequired type={'date'} label="To" formik={formik} name="to" />
                    {/* <FormInput isRequired type={'date'} label="Registration Closing Date" formik={formik} name="registration_closing_date" /> */}
                </SimpleGrid>
            </SectionCard>

            <SectionCard backgroundColor=" bg-white" title="Other" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 1, xl: 1 }} spacing={3}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                        <FormSelect components={{ Control: FormSelectColourController, Option: customComponents.Option }} options={colourOptions}
                            label="Color" formik={formik}
                            name="colour" />

                        <FormDropdown options={[{ "value": 0, "label": "No" }, { "value": 1, "label": "Yes" }]} formik={formik}
                            name={'allow_feedback'}
                            autoFocus
                            label={'Allow Feedback '} />
                    </SimpleGrid>

                    <FormTextArea rows={7} label="Description" formik={formik} name="description" />

                </SimpleGrid>

            </SectionCard>
        </div>
    )
}
export default NewProgramPage

const programModes = [{
    value: "Online",
    label: "Online"
}, {
    value: "Physical",
    label: "Physical"
}, {
    value: "Hybrid",
    label: "Hybrid"
}]

const colourOptions = [
    { "value": "#b3e6ff", "label": "" },
    { "value": "#cceeff", "label": "" },
    { "value": "#e6f7ff", "label": "" },
    { "value": "#ffb3ec", "label": "" },
    { "value": "#e6b3ff", "label": "" },
    { "value": "#b3ffb3", "label": "" },
    { "value": "#ffd1b3", "label": " " },
    { "value": "#ffb3d1", "label": "" },
    { "value": "#ccffee", "label": "" },
    { "value": "#ffffcc", "label": "" },
    { "value": "#e6ccff", "label": "" },
    { "value": "#ccffff", "label": "" }
]


const customComponents = {
    Option: ({ children, ...props }: any) => {
        console.log(props, 'propa')
        return (<chakraComponents.Option {...props}>
            <div className=" h-[32px] w-[32px] rounded-full" style={{ backgroundColor: props.data.value }}>
            </div>
        </chakraComponents.Option >)
    }
};

