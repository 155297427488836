import moment from "moment";
import * as Yup from 'yup';
import { printAction } from "../../../actions";
import { PRINT } from "../../../consts/methods.consts";

const StudentEnrolAndAttSummaryConfig = {
    searchApi: "xceed.api.report.programmed_module.student_enrolment_and_attendance_summary_report",
    alert:
        "Reminder: Specify a date range to receive reports.",
    excelFileName: "student-enrolment-and-summary-report",
    title: "Student Enrolment & Attendance Summary Report",
    extraActionsAsMenu: true,
    sections: [
        {
            selectionTitle: "Date Range",
            grid: { base: 1, md: 2, lg: 4, xl: 4 },
            filters: [
                {
                    is_required: true,
                    label: "From",
                    type: "date",
                    key: "from",
                    validationSchema: Yup.string().required('From is required'),
                },
                {
                    is_required: true,
                    label: "To",
                    type: "date",
                    key: "to",
                    validationSchema: Yup.string().required('To is required'),
                },
            ],
        }
    ],

    tableConfig: {
        column: [
            {
                header: "PA Name",
                accessor: "pa",
                width: 300,
            },
            {
                header: "Pending Module Enrolment",
                accessor: "pending_enrolment"
            },
            {
                header: "Pending Attendance",
                accessor: "pending_attendance"
            },
            {
                header: "Pending Assessment Result",
                accessor: "pending_assessment_result"
            }
        ],
    },
    actions: [
        {
            text: "Print Report",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.from && filter.to) {
                    setLoading(true);
                    try {
                        await printAction(PRINT.PRINT_STUDENT_ENROLMENT_AND_ATTENDANCE_REPORT, { filter }, setLoading);
                    } catch (error: any) {
                        setLoading(false);
                        const newError: any = new Error("Unable to print report");
                        newError.title = "Error";
                        newError.status = "error";
                        throw newError;
                    }
                } else {
                    const error: any = new Error(
                        "Please select the from date and to date"
                    );
                    error.title = "Warning";
                    error.status = "warning";
                    throw error;
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal",
        },
    ],
};

export default StudentEnrolAndAttSummaryConfig;