import { SimpleGrid, FormLabel } from "@chakra-ui/react"
import _ from "lodash"
import { SectionCard } from "../section-card/SectionCard"

const DocumentHeaderTitle = ({ children, labelClassName = '!opacity-75 !text-[20px]' }: any) => {
    return (
        <SectionCard backgroundColor=" bg-white" p={3}>
            <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                <FormLabel className={labelClassName}>{children}</FormLabel>
            </SimpleGrid>
        </SectionCard>
    )
}

export default DocumentHeaderTitle