const reportPermission: any = {
    "employee": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "session": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "program": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "examRegistration": ['Admin', 'System Manager', 'Exam Division'],
    "attendance": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "assesment": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "daily-program": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "classroom": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "custom/attendance-report": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "resource-person": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "module-plan-amendment": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "module-plan": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "module-allocation-consultant": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "module-allocation-program-assistant": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "custom/student-enrollment-report": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "pending-module-plan": ['Consultant', 'ADG', 'DG'],
    "pending-amendment-request": ['Consultant'],
    "custom/session-report-v2": ['Consultant'],
    "custom/lecture-hours-v2": ['Consultant', 'ADG', 'DG'],
    "custom/library-access-report": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "module-allocation-yearly": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG'],
    "student-enrolment-&-summary-report": ['Admin', 'System Manager', 'Program Officer', 'Consultant', 'Program Assistant', 'Registrar', 'ADG', 'DG']
}

export default reportPermission

