export enum MODULE {
    LIST = "xceed.api.module.get_list",
    ADD = "xceed.api.module.add",
    GET = "xceed.api.module.get",
    UPDATE = "xceed.api.module.update",
    ADD_NEW_CONTENT = "xceed.api.module.add_new_content",
    GET_LIST_FOR_DROPDOWN = "xceed.api.module.get_list_for_dropdown",
    DELETE = "xceed.api.module.delete"
}

export enum PROGRAMMED_MODULE {
    LIST_APPROVAL = "xceed.api.programmed_module.get_list_approval",
    LIST = "xceed.api.programmed_module.get_list",
    ADD = "xceed.api.programmed_module.add",
    GET = "xceed.api.programmed_module.get",
    UPDATE = "xceed.api.programmed_module.update",
    ADD_NEW_CONTENT = "xceed.api.programmed_module.add_new_content",
    GET_LIST_FOR_DROPDOWN = "xceed.api.programmed_module.get_list_for_dropdown",
    EXECUTE_WORKFLOW_ACTION = "xceed.api.programmed_module.exceute_workflow_action",
    GET_TRANSACTION = "xceed.api.programmed_module.get_transactions",
    MARK_AS_CLOSE = "xceed.api.programmed_module.mark_as_close",
    DELETE = "xceed.api.programmed_module.mark",
    INIT = "xceed.api.programmed_module.init",
    CLOSE_AMENDMENTREQUEST = "xceed.api.programmed_module.close_amendment_request"
}

export enum MODULE_BUCKET_TYPE {
    LIST = "xceed.api.module_bucket_type.get_list",
    ADD = "xceed.api.module_bucket_type.add",
    GET = "xceed.api.module_bucket_type.get",
    UPDATE = "xceed.api.module_bucket_type.update",
    DELETE = "xceed.api.module_bucket_type.delete"
}

export enum MODULE_BUCKET {
    LIST = "xceed.api.module_bucket.get_list",
    ADD = "xceed.api.module_bucket.add",
    GET = "xceed.api.module_bucket.get",
    UPDATE = "xceed.api.module_bucket.update",
    UPDATE_MODULE_LINE = "xceed.api.module_bucket.update_module_line",
    GET_MODULE_MODULE_DETAILS = "xceed.api.module_bucket.update_module_line",
    CREATE = "xceed.api.module_bucket.add",
    INIT = "xceed.api.module_bucket.init",
    DELETE = "xceed.api.module_bucket.delete"
}

export enum MODULE_ENROLLMENT {
    LIST = "xceed.api.module_enrollment.get_list",
    UPLOAD_EXCEL = "xceed.api.module_enrollment.upload_from_excel",
    ADD = "xceed.api.module_enrollment.add",
    GET_STUDENT_LIST = "xceed.api.module_enrollment.get_student_list",
    GET_ENROLLED_STUDENTS = "xceed.api.module_enrollment.get_enrolled_student_list",
    DELETE = "xceed.api.module_enrollment.delete",
}

export enum ATTENDANCE {
    LIST = "xceed.api.attendance.get_list",
    ADD = "xceed.api.attendance.add",
    GET = "xceed.api.attendance.get",
    UPDATE = "xceed.api.attendance.update",
    UPDATE_MODULE_LINE = "xceed.api.attendance.attendance",
    GET_MODULE_MODULE_DETAILS = "xceed.api.attendance.update_module_line",
    UPLOAD_EXCEL = "xceed.api.attendance.import_attendance",
    UPLOAD_EXCEL_FROM_PM = "xceed.api.attendance.import_attendance_from_module",
    CREATE = "xceed.api.attendance.add",
    INIT = "xceed.api.attendance.init",
    DELETE = "xceed.api.attendance.delete",
    CHECK_CERTIFICATE_VALIDITY = "xceed.api.attendance.check_generation_certificate_validity"
}

export enum CLASS_ROOM {
    LIST = "xceed.api.class_room.get_list",
    ADD = "xceed.api.class_room.add",
    GET = "xceed.api.class_room.get",
    UPDATE = "xceed.api.class_room.update",
    UPDATE_MODULE_LINE = "xceed.api.class_room.attendance",
    GET_MODULE_MODULE_DETAILS = "xceed.api.class_room.update_module_line",
    CREATE = "xceed.api.class_room.add",
    INIT = "xceed.api.class_room.init",
    DELETE = "xceed.api.class_room.delete"
}


export enum ORGANIZATION {
    LIST = "xceed.api.organization.get_list",
    ADD = "xceed.api.organization.add",
    GET = "xceed.api.organization.get",
    UPDATE = "xceed.api.organization.update",
    UPDATE_MODULE_LINE = "xceed.api.organization.update_module_line",
    GET_MODULE_MODULE_DETAILS = "xceed.api.organization.update_module_line",
    CREATE = "xceed.api.organization.add",
    INIT = "xceed.api.organization.init",
    DELETE = "xceed.api.organization.delete"
}

export enum EMPLOYEE {
    LIST = "xceed.api.employee.get_list",
    ADD = "xceed.api.employee.add",
    GET = "xceed.api.employee.get",
    UPDATE = "xceed.api.employee.update",
    UPDATE_MODULE_LINE = "xceed.api.employee.update_module_line",
    GET_MODULE_MODULE_DETAILS = "xceed.api.employee.update_module_line",
    CREATE = "xceed.api.employee.add",
    INIT = "xceed.api.employee.init",
    DELETE = "xceed.api.employee.delete"
}

export enum EXAM_ENROLLMENT {
    LIST = "xceed.api.exam_enrollment.get_list",
    ADD = "xceed.api.exam_enrollment.add",
    ADD_ATTCHMENT = "xceed.api.exam_enrollment.add_attachment",
    GET = "xceed.api.exam_enrollment.get",
    UPDATE = "xceed.api.exam_enrollment.update",
    INIT = "xceed.api.exam_enrollment.init",
    DELETE = "xceed.api.exam_enrollment.delete",
    GET_ES_DETAILS = "xceed.api.exam_enrollment.get_exam_subject",
    MARK_ACCEPT_REJECT = "xceed.api.exam_enrollment.mark_accept",
}


export enum PROGRAM {
    LIST = "xceed.api.program.get_list",
    GET = "xceed.api.program.get",
    UPDATE = "xceed.api.program.update",
    CREATE = "xceed.api.program.add",
    INIT = "xceed.api.program.init",
    PROGRAM_STATUS = "xceed.api.program.check_module_completeness",
    GET_CONTACT_NUMBER = "xceed.api.program.get_contact_number",
    DELETE = "xceed.api.program.delete",
    GET_BUDGET_DETAILS = "xceed.api.program.get_approved_budget_doc_details",
}

export enum SESSION {
    LIST = "xceed.api.session.get_list",
    LIST_WORKFLOW = "xceed.api.session.session_list_for_workflow",
    ADD = "xceed.api.session.add",
    GET = "xceed.api.session.get",
    UPDATE = "xceed.api.session.update",
    INIT = "xceed.api.session.init",
    DELETE = "xceed.api.session.delete",
    PRINT_QR = "xceed.api.print.print_qr",
    SESSION_ATTENDANCE_LIST = "xceed.api.session.get_attendance_for_session",
    EXEC_WORKFLOW_ACTION = "xceed.api.session.exceute_workflow_action",
    GET_TRANSACTION = "xceed.api.session.get_transactions",
    GET_RESOURCE_PERSONS_LIST = "xceed.api.session.get_resource_person_list",
    GET_CLASSROOM_LIST = "xceed.api.session.get_class_room_list",
    GET_COMBINE_SESSIONS = "xceed.api.session.get_combine_sessions",
}

export enum STUDENT {
    LIST = "xceed.api.student.get_list",
    ADD = "xceed.api.student.add",
    GET = "xceed.api.student.get",
    UPDATE = "xceed.api.student.update",
    INIT = "xceed.api.student.init",
    DELETE = "xceed.api.student.delete",
    GET_SERVICES = "xceed.api.student.get_services",
    GET_ENROLLED_PROGRAM_LIST = "xceed.api.student.get_enrolled_programs",
    CREATE_USER_ACCOUNT = "xceed.api.student.create_user_profile"
}

export enum STUDENT_PROFILE {
    PROGRAM_SUMMARY = "xceed.api.student_profile.program_summary",
    SELECTED_PROGRAM_SUMMARY = "xceed.api.student_profile.selected_program_summary",
    ATTENDACE_SUMMARY = "xceed.api.student_profile.attendance_summary",
    MODULE_SUMMARY = "xceed.api.student_profile.module_summary",
    GET = "xceed.api.student.get",
    UPDATE = "xceed.api.student.update",
    INIT = "xceed.api.student.init",
    DELETE = "xceed.api.student.delete",
}

export enum PROGRAM_CATEGORY {
    LIST = "xceed.api.program_category.get_list",
    ADD = "xceed.api.program_category.add",
    GET = "xceed.api.program_category.get",
    UPDATE = "xceed.api.program_category.update",
    INIT = "xceed.api.program_category.init",
    DELETE = "xceed.api.program_category.delete",
}

export enum PROGRAM_TYPE {
    LIST = "xceed.api.program_type.get_list",
    ADD = "xceed.api.program_type.add",
    GET = "xceed.api.program_type.get",
    UPDATE = "xceed.api.program_type.update",
    INIT = "xceed.api.program_type.init",
    DELETE = "xceed.api.program_type.delete",
}

export enum PROGRAM_SUBTYPE {
    LIST = "xceed.api.program_subtype.get_list",
    ADD = "xceed.api.program_subtype.add",
    GET = "xceed.api.program_subtype.get",
    UPDATE = "xceed.api.program_subtype.update",
    INIT = "xceed.api.program_subtype.init",
    DELETE = "xceed.api.program_subtype.delete",
}

export enum CALENDAR {
    SESSIONS = "xceed.api.calendar.get_sessions",
    PROGRAMS = "xceed.api.calendar.get_programs",
    EVENTS = "xceed.api.calendar.get_events",
    SESSION_AGENDA = "xceed.api.calendar.get_sessions_for_agenda",
    PROGRAM_AGENDA = "xceed.api.calendar.get_programs_for_agenda",
    MODULE_LIST = "xceed.api.calendar.get_modules"
}

export enum RESOURCE_PERSON {
    LIST = "xceed.api.resource_person.get_list",
    ADD = "xceed.api.resource_person.add",
    GET = "xceed.api.resource_person.get",
    UPDATE = "xceed.api.resource_person.update",
    UPDATE_MODULE_LINE = "xceed.api.resource_person.update_module_line",
    GET_MODULE_MODULE_DETAILS = "xceed.api.resource_person.update_module_line",
    CREATE = "xceed.api.resource_person.add",
    INIT = "xceed.api.resource_person.init",
    DELETE = "xceed.api.resource_person.delete",
}

export enum DASHBOARD {
    GET_STAT = "xceed.api.dashboard.get_stats",
    GET_STUDENT_COUNT_BY_YEAR = "xceed.api.dashboard.get_student_count_by_year",
}

export enum CONSULTANT_DASHBOARD {
    GET_STAT = "xceed.api.dashboard.consultant.get_stats_for_consultant",
    GET_STUDENT_COUNT_BY_YEAR = "xceed.api.dashboard.get_student_count_by_year",
}

export enum PRINT {
    PRINT_SESSION_PLAN = "xceed.api.print.print_session_plan",
    PRINT_DAILY_PROGRAM = "xceed.api.print.print_daily_program",
    PRINT_ADMISSION = "xceed.api.print.print_admission",
    PRINT_TRAINING_PROGRAMMES_SCHEDULE = "xceed.api.print.print_training_programmes_schedule",
    PRINT_MONTHLY_PROGRAMMES_SCHEDULE = "xceed.api.print.print_monthly_program_schedule",
    PRINT_WEEKLY_PROGRAMMES_SCHEDULE = "xceed.api.print.print_weekly_program_schedule",
    PRINT_ATTENDANCE_CERTIFICATE = "xceed.api.print.print_attendance_certificate",
    PRINT_AMENDMENT_REQUEST_BULK_REPORT = "xceed.api.print.print_amendment_request_bulk",
    PRINT_AMENDMENT_REQUEST_MC_WISE_REPORT = "xceed.api.print.print_amendment_request_mc_wise",
    PRINT_PROGRAMMED_MODULE_WORKFLOW_MC_WISE_REPORT = "xceed.api.print.programmed_module_workflow_print",
    PRINT_PROGRAMMED_MODULE_WORKFLOW_BULK_REPORT = "xceed.api.print.programmed_module_workflow_bulk_print",
    PRINT_CONSULTANT_MODULE_ALLOCATION_BULK_REPORT = "xceed.api.print.consultant_module_allocation_bulk_print",
    PRINT_CONSULTANT_MODULE_ALLOCATION_REPORT = "xceed.api.print.consultant_module_allocation_print",
    PRINT_PROGRAM_ASSISTANCE_MODULE_ALLOCATION_BULK_REPORT = "xceed.api.print.program_assistant_module_allocation_bulk_print",
    PRINT_PROGRAM_ASSISTANCE_MODULE_ALLOCATION_REPORT = "xceed.api.print.program_assistant_module_allocation_print",
    PRINT_MODULE_ALLOCATION_YEARLY_PA = "xceed.api.print.pa_module_allocation_yearly",
    PRINT_STUDENT_ENROLMENT_AND_ATTENDANCE_REPORT = "xceed.api.print.student_enrolment_and_attendance_summary_report"
}

export enum DATAIMPORT {
    LIST = "xceed.api.data_import.get_list",
    CREATE = "xceed.api.data_import.create",
    GET = "xceed.api.data_import.get",
    ADD_ATTACHMENT = "xceed.api.data_import.upload_to_doc",
    REMOVE_ATTCHMENT = "xceed.api.data_import.romove_attached_file",
    DELETE = "xceed.api.data_import.delete",
    START_IMPORT = "xceed.api.data_import.start_import"
}

export enum EXAM {
    LIST = "xceed.api.exam.get_list",
    ADD = "xceed.api.exam.add",
    INIT = "xceed.api.exam.init",
    GET = "xceed.api.exam.get",
    UPDATE = "xceed.api.exam.update",
    DELETE = "xceed.api.exam.delete",
    EDIT_SUBJECT = "xceed.api.exam.edit_subject",
    ADD_SUBJECT = "xceed.api.exam.add_subject",
    DELETE_SUBJECTS = "xceed.api.exam.delete_subject"
}

export enum SERVICE {
    LIST = "xceed.api.service.get_list",
    ADD = "xceed.api.service.add",
    GET = "xceed.api.service.get",
    UPDATE = "xceed.api.service.update",
    DELETE = "xceed.api.service.delete",
}

export enum SUBJECT {
    LIST = "xceed.api.subject.get_list",
    ADD = "xceed.api.subject.add",
    GET = "xceed.api.subject.get",
    UPDATE = "xceed.api.subject.update",
    INIT = "xceed.api.subject.init",
    DELETE = "xceed.api.subject.delete",
}

export enum MODULE_ENROLLMENT_REQUEST {
    LIST = "xceed.api.module_enrollment_request.get_list",
    ACCEPT = "xceed.api.module_enrollment_request.accept_module_enrollment",
    REJECT = "xceed.api.module_enrollment_request.reject_module_enrollment",
    DELETE = "xceed.api.module_enrollment_request.delete",
}

export enum MODULE_ASSESMENT_RESULTS {
    LIST = "xceed.api.module_assessment.get_list",
    UPLOAD_EXCEL = "xceed.api.module_assessment.upload_from_excel",
    ADD = "xceed.api.module_enrollment.add",
    DELETE = "xceed.api.module_assessment.delete",
}

export enum COMMENTS {
    GET_LIST = "xceed.api.comment.get_list",
    UPDATE = "xceed.api.comment.update",
    CREATE = "xceed.api.comment.create",
    DELETE = "xceed.api.comment.delete",
}

export enum SIDEBAR {
    GET_LINKS = "xceed.api.sidebar.get_config",
    GET_USER_ROLES = "xceed.api.sidebar.get_user_and_roles"
}

export enum AUTH {
    FORGOT_PASSWORD = "xceed.api.auth.forgot_password",
    CHANGE_PASSWORD = "xceed.api.auth.change_password"
}


export enum RESERVED_CLASSROOM {
    GET_LIST = "xceed.api.reserved_classroom.get_list",
    GET_AVAILABLE_CLASS_ROOMS = "xceed.api.reserved_classroom.get_available_classroom",
    ADD = "xceed.api.reserved_classroom.create",
    GET = "xceed.api.reserved_classroom.get",
    UPDATE = "xceed.api.reserved_classroom.update",
    DELETE = "xceed.api.reserved_classroom.delete",
}

export enum AMENDMENTS {
    GET_AMENDMENT_ALLOW_FIELDS = "xceed.api.amendment.get_allow_field_by_doctype",
    CREATE_AMENDMENT_REQUEST = "xceed.api.amendment.create",
    LIST = "xceed.api.amendment.get_list",
    GET = "xceed.api.amendment.get",
    EXECUTE_WORKFLOW_ACTION = "xceed.api.amendment.execute_workflow_action",
    GET_AMENDMENTS_HISTORY = "xceed.api.amendment.get_amendments_history",
}


export enum PROGRAM_MODULE_APPROVES {
    GET_LIST = "xceed.api.programmed_module.get_approve_history",
}

export enum MODULE_ENROLLMENT_UPLOAD_LOG {
    GET_LIST = "xceed.api.module_enrollment.get_module_enrolment_upload_logs",
    GET_MODULE_ENROLMENT_LOGS = "xceed.api.module_enrollment.get_module_enrolment_logs",
}

