import { Center, HStack, Menu, MenuButton, MenuItem, MenuList, useToast } from "@chakra-ui/react"
import _, { get } from "lodash"
import { useState } from "react"
import { FaEllipsisH } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { NavigateFunction, useNavigate, useParams } from "react-router-dom"
import { ATTENDANCE, SESSION } from "../../../../consts/methods.consts"
import { ATTENDANCE_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery, usePostMutation } from "../../../../services/api.service"
import { ToastService } from "../../../../services/toast.service"
import { showConfirmDialog } from "../../../../store/reducers/confirm-modal"
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable"
import PageHeader from "../../../common/page-header/PageHeader"
import { useTableDataProvider } from "../../../hooks"

const AttendanceTable = () => {
    const navigate: NavigateFunction = useNavigate()
    const { id } = useParams()
    const [page, setPage] = useState(1)
    const [create] = usePostMutation();
    const [searchKey, setSearchKey]: any = useState()
    const [length, setLength] = useState(15)
    const toast = new ToastService(useToast());
    const dispatch = useDispatch()

    const { data, isLoading, isFetching } = useGetQuery({
        method: SESSION.SESSION_ATTENDANCE_LIST,
        body: {
            page: page,
            key: searchKey,
            id,
            length: Number(length),
        },
        providesTags: [ATTENDANCE_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const ondeleteHandler = async (item: any) => {
        dispatch(
            showConfirmDialog({
                title: 'Confirmation Required',
                subtitle: 'Are you sure, you want to delete this attendance document',
                onConfirm: async() => {
                    if (!item?.attendance_id) {
                        toast.setTitle("Warning").setDescription("The attendance document you are trying to delete does not exist in the system")
                            .setStatus('warning').show();
                        return
                    }
                    try {
                        const res = await create({
                            method: ATTENDANCE.DELETE,
                            body: { name: item?.attendance_id },
                            invalidatesTags: [ATTENDANCE_TAGS.LIST]
                        }).unwrap();

                        toast.setTitle("Success").setDescription("Attendance Document is deleted successfully.").showSuccessToast();

                    } catch (error: any) {
                        let message = JSON.parse(_.get(error?.data, '_server_messages', {}))
                        if (message) toast.setTitle("Error").setDescription(JSON.parse(message)?.message).showErrorToast();
                    }
                }
            }))


    }


    const ATTENDANCE_LIST_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Student",
            accessor: "full_name",
        },
        {
            header: "Status",
            accessor: "status",
            width: 400,
            customRenderer(value: any, item) {
                const className = (value == 'Present') ? 'bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded' :
                    'bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded'
                return (
                    <div>
                        <span className={className}>{value}</span>
                    </div>
                )
            },
        },

        {
            header: "Actions",
            accessor: '',
            disabled: false,
            width: 100,
            customRenderer(value: any, item) {
                const status = get(item, 'status')
                if (status != 'Present') {
                    return <></>
                }
                return (
                    <Center>
                        <HStack spacing={5}>
                            <Menu>
                                <MenuButton>
                                    <FaEllipsisH />
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => ondeleteHandler(item)}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        </HStack>
                    </Center>
                )
            },
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`/app/student/${_.get(line, 'student_id')}`)
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Attendance" title={`Attendance`} />
            <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header title="Attendance"
                onSelected={tableLineClickHandler} columns={ATTENDANCE_LIST_TAB_COLUMNS} data={dataList} />
        </div>
    )
}

export default AttendanceTable

