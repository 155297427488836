import moment from "moment";
import * as Yup from 'yup';
import { printAction } from "../../../actions";
import { PRINT } from "../../../consts/methods.consts";
const currentYear = moment().year();

const ModuleAllocationPAYearlyConfig = {
  searchApi: "xceed.api.report.programmed_module.module_allocation_yearly_pa_report",
  alert:
    "Reminder: Specify a year to receive reports. Use additional fields to further refine the data within the selected year.",
  excelFileName: "module-allocation-yearly",
  title: "Module Allocation Report (Yearly) - PA",
  extraActionsAsMenu: true,
  sections: [
    {
      selectionTitle: "Report Year",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          is_required: true,
          label: "Year",
          type: "drop-down",
          key: "year",
          validationSchema: Yup.string().required('Year is required'),
          options: Array.from({ length: 7 }, (_, i) => {
            const year = currentYear - 3 + i;
            return { label: year, value: year };
          })
        },
      ],
    },
    {
      selectionTitle: "Filters",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          label: "Status",
          key: "status",
          type: "drop-down",
          options: [
            {
              "label": "All", "value": "All"
            },
            {
              "label": "Open", "value": "0"
            },
            {
              "label": "Completed", "value": "1"
            }
          ]
        },
      ],
    },
  ],

  tableConfig: {
    column: [
      {
        header: "PA",
        accessor: "pa",
        width: 300,
      },
      {
        header: "January",
        accessor: "1"
      },
      {
        header: "February",
        accessor: "2"
      },
      {
        header: "March",
        accessor: "3"
      },
      {
        header: "April",
        accessor: "4"
      },
      {
        header: "May",
        accessor: "5"
      },
      {
        header: "June",
        accessor: "6"
      },
      {
        header: "July",
        accessor: "7"
      },
      {
        header: "August",
        accessor: "8",
      },
      {
        header: "September",
        accessor: "9",
      },
      {
        header: "October",
        accessor: "10",
      },
      {
        header: "November",
        accessor: "11",
      },
      {
        header: "December",
        accessor: "12",
      },
    ],
  },
  actions: [
    {
      text: "Print Report",
      onClick: async (filter: any, data: any, setLoading: any) => {
        if (filter.year) {
          setLoading(true);
          try {
            await printAction(PRINT.PRINT_MODULE_ALLOCATION_YEARLY_PA, { filter }, setLoading);
          } catch (error: any) {
            setLoading(false);
            const newError: any = new Error("Unable to print report");
            newError.title = "Error";
            newError.status = "error";
            throw newError;
          }
        } else {
          const error: any = new Error(
            "Please select a year"
          );
          error.title = "Warning";
          error.status = "warning";
          throw error;
        }
      },
      buttonVariant: "solid",
      buttonColorScheme: "teal",
    },
  ],
};

export default ModuleAllocationPAYearlyConfig;