import axios from "axios";
import _ from "lodash";
import React from "react";
import { BASE_URL } from "../../../consts/app.consts";
import AssesmentConfig from "./AssesmentConfig";
import ClassroomConfig from "./ClassroomConfig";
import { DefaultCell, ResourcePersonCell } from "./components/cells";
import dailyProgramConfig from "./dailyProgramConfig";
import examRegistrationConfig from "./ExamRegistrationConfig";
import ModuleAllocationConsultantConfig from "./ModuleAllocationConsultantConfig";
import ModuleAllocationPAConfig from "./ModuleAllocationPAConfig";
import ModuleAllocationPAYearlyConfig from "./ModuleAllocationPAYearlyConfig";
import ModulePlanAmendmentConfig from "./ModulePlanAmendmentConfig";
import ModulePlanConfig from "./ModulePlanConfig";
import PendingAmendmentRequestConfig from "./PendingAmendmentRequestConfig";
import PendingModulePlanConfig from "./PendingModulePlanConfig";
import programConfig from "./ProgramConfig";
import ResourcePersonConfig from "./ResourcePersonConfig";
import StudentEnrolAndAttSummaryConfig from "./StudentEnrolAndAttSummaryConfig";
import StudentEnrollmentConfig from "./StudentEnrollmentConfig";

const employee = {
  searchApi: "xceed.api.report.employee_generic_report",
  alert:
    "Reminder: Specify a date range to receive reports. Use other fields to further narrow down data within the required date range.",
  excelFileName: "employee-report",
  sections: [
    {
      selectionTitle: "Date Range",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          is_required: true,
          label: "From",
          type: "date",
          key: "from",
        },
        {
          is_required: true,
          label: "To",
          type: "date",
          key: "to",
        },
      ],
    },
    {
      selectionTitle: "Filters",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          label: "Full Name",
          key: "full_name",
          type: "text",
        },
        {
          label: "First Name",
          key: "first_name",
          type: "text",
        },
        {
          label: "Last Name",
          key: "last_name",
          type: "text",
        },
        {
          label: "Type",
          key: "type",
          type: "drop-down",
          options: [
            { value: "Academic", label: "Academic" },
            { value: "Non Academic", label: "Non Academic" },
          ],
        },
        {
          label: "Location",
          type: "text",
          key: "location",
        },
        {
          label: "NIC",
          type: "text",
          key: "nic",
        },

        {
          label: "Order By",
          type: "drop-down",
          key: "order_by",
          options: [
            { value: "full_name", label: "First Name" },
            { value: "last_name", label: "Last Name" },
            { value: "creation", label: "Created date" },
          ],
        },
        {
          label: "Order",
          type: "drop-down",
          key: "order",
          options: [
            { value: "DESC", label: "DESC" },
            { value: "ASC", label: "ASC" },
          ],
        },
      ],
    },
  ],

  title: "Employee Report",

  tableConfig: {
    column: [
      {
        header: "NIC",
        accessor: "nic",
        width: 200,
      },
      {
        header: "Salutation",
        accessor: "salutation",
        width: 200,
      },
      {
        header: "Employee Name",
        accessor: "full_name",
        customRenderer: (value: any, item: any) =>
          React.createElement(DefaultCell, {
            navigateTo: `/app/employee/${_.get(item, "name")}`,
            value: value,
          }),
        width: 200,
      },
      {
        header: "Type",
        accessor: "type",
        width: 200,
      },
      {
        header: "Location",
        accessor: "location",
        width: 200,
      },
    ],
  },
};

const session = {
  searchApi: "xceed.api.report.session_generic_report",
  alert:
    "Reminder: Specify a date range to receive reports. Use other fields to further narrow down data within the required date range.",
  excelFileName: "session-report",
  title: "Session Details Report",
  sections: [
    {
      selectionTitle: "Date Range",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          is_required: true,
          label: "From",
          type: "date",
          key: "from",
        },
        {
          is_required: true,
          label: "To",
          type: "date",
          key: "to",
        },
      ],
    },
    {
      selectionTitle: "Filters",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        // {
        //     label: 'Session Topic',
        //     key: 'title',
        // },
        {
          label: "Program",
          key: "program",
          type: "form-select",
          optionMethod: "xceed.api.report.get_program_list",
        },
        {
          label: "Resource Person",
          key: "resource_persons",
          type: "form-select",
          optionMethod: "xceed.api.report.get_resourse_persons",
        },
        {
          label: "Program Assistant",
          key: "program_assistant",
          type: "form-select",
          optionMethod: "xceed.api.report.get_program_assistant",
        },
        // {
        //     label: 'Class Room',
        //     key: 'class_room',
        //     type: 'form-select',
        //     optionMethod: 'xceed.api.report.get_class_room'
        // },

        {
          label: "Order By",
          type: "drop-down",
          key: "order_by",
          options: [
            { value: "P.title", label: "Program" },
            { value: "EE.first_name", label: "Resource Person" },
            { value: "E.first_name", label: "Program Assistant" },
            { value: "S.title", label: "Session Topic" },
            { value: "S.start_date_andtime", label: "Start Date" },
          ],
        },
        // {
        //     label: 'Order',
        //     type: 'drop-down',
        //     key: 'order',
        //     options: [
        //         { value: 'DESC', label: 'DESC' },
        //         { value: 'ASC', label: 'ASC' },
        //     ]
        // }
      ],
    },
  ],

  tableConfig: {
    column: [
      {
        header: "Date",
        accessor: "date",
        width: 200,
      },
      {
        header: "Start Time",
        accessor: "start",
        width: 200,
      },
      {
        header: "End Time",
        accessor: "end",
        width: 200,
      },
      {
        header: "Session Topic",
        accessor: "title",
        // customRenderer: (value: any, item: any) => (
        //     React.createElement(DefaultCell, { navigateTo: `/app/session/${_.get(item, 'name')}`, value: value })
        // ),
        width: 400,
      },
      {
        header: "Module Name",
        accessor: "module_name",
        // customRenderer: (value: any, item: any) => (
        //     React.createElement(DefaultCell, { navigateTo: `/app/modules/${_.get(item, 'module')}`, value: value })
        // ),
        width: 400,
      },
      {
        header: "Program Name",
        accessor: "Program_name",
        // customRenderer: (value: any, item: any) => (
        //     React.createElement(DefaultCell, { navigateTo: `/app/program/${_.get(item, 'program')}`, value: value })
        // ),
        width: 400,
      },
      {
        header: "Resource Person",
        accessor: "resource_persons",
        customRenderer: (value: any, item: any) =>
          React.createElement(ResourcePersonCell, {
            navigateTo: `/app/employee/${_.get(item, "name")}`,
            values: value,
          }),
        width: 200,
      },
      {
        header: "Program Assistant",
        accessor: "program_assistant",
        // customRenderer: (value: any, item: any) => (
        //     React.createElement(DefaultCell, { navigateTo: `/app/employee/${_.get(item, 'program_assistant_name')}`, value: value })
        // ),
        width: 200,
      },
      {
        header: "Class Room",
        accessor: "class_name",
        width: 50,
      },
    ],
  },
  actions: [
    {
      text: "Export to Excel",
      onClick: async (filter: any, data: any, setLoading: any) => {
        if (filter.to && filter.from) {
          setLoading(true);
          try {
            let response = await axios.post(
              `${BASE_URL}/method/xceed.api.export_excel.session_report.export_session_report`,
              { filter },
              {
                withCredentials: true,
                responseType: "blob",
              }
            );
            if (response) {
              console.log(response);
              const href = URL.createObjectURL(response.data);

              const link = document.createElement("a");
              link.href = href;
              link.setAttribute("download", `sessions-export.xlsx`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            }
            setLoading(false);
          } catch (error: any) {
            setLoading(false);
            const newError: any = new Error("Unable to Generate Excel file");
            newError.title = "Error";
            newError.status = "error";
            throw newError;
          }
        } else {
          const error: any = new Error("Please select from date and to date");
          error.title = "Warning";
          error.status = "warning";
          throw error;
        }
      },
      buttonVariant: "solid",
      buttonColorScheme: "teal",
    },
  ],
};

const config: any = {
  "employee": employee,
  "session": session,
  "program": programConfig,
  "examRegistration": examRegistrationConfig,
  "assesment": AssesmentConfig,
  "daily-program": dailyProgramConfig,
  "classroom": ClassroomConfig,
  "resource-person": ResourcePersonConfig,
  "module-plan-amendment": ModulePlanAmendmentConfig,
  "module-plan": ModulePlanConfig,
  'module-allocation-consultant': ModuleAllocationConsultantConfig,
  "module-allocation-program-assistant": ModuleAllocationPAConfig,
  "student-enrolment-report": StudentEnrollmentConfig,
  "pending-module-plan":PendingModulePlanConfig,
  "pending-amendment-request":PendingAmendmentRequestConfig,
  "module-allocation-yearly":ModuleAllocationPAYearlyConfig,
  "student-enrolment-&-summary-report":StudentEnrolAndAttSummaryConfig
  
};

export default config;
